import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

import logo from "./assets/logo.png";
import "./login.css";

function DialogDefault() {
  const [open, setOpen] = React.useState(true);

  const handleOpen = () => setOpen(!open);

  return (
    <>
      <Dialog open={open} handler={handleOpen} outside>
        <DialogHeader className="justify-center">DISCLAIMER</DialogHeader>
        <DialogBody divider className="h-[40rem] overflow-y-scroll">
          Ini adalah situs layanan sosial yang mempertemukan donatur dan pencari
          donasi. Layanan di portal ini bersifat gratis, tidak dipungut biaya
          apapun. Jika ada kontak yang mengaku dari kami dan meminta biaya
          layanan, itu adalah bentuk penipuan. <br></br>
          <br></br> Semua materi dan foto yang diunggah pemberi donasi, adalah
          tanggung jawab pribadi dari pengunggah. Kami selaku penyedia layanan,
          tidak bertangung jawab atas unggahan ini. <br></br>
          <br></br> Segala unggahan tidak boleh mengandung unsur SARA (Suku,
          Agama, Ras, dan Antargolongan). Tanpa perlu konfirmasi, kami secara
          sepihak dapat menghapus unggahan apabila menemukan adanya unsur SARA.{" "}
          <br></br>
          <br></br>
          Dalam menggunakan layanan di situs ini, nomor telepon dan atau email
          pengguna akan terpapar ke publik. Kami selaku penyedia layanan, tidak
          bertanggung jawab apabila terjadi penyalahgunaan informasi ini.{" "}
          <br></br>
          <br></br> Kami menghimbau agar tidak mengunggah iklan komersial apapun
          pada layanan situs ini. Kami berhak menghapus tanpa perlu konfirmasi,
          atas segala bentuk iklan komersial yang diunggah.<br></br>
          <br></br>
          PERSETUJUAN <br></br>
          <br></br> Dengan menggunakan situs layanan kami, maka Anda setuju
          untuk mengikuti syarat dan ketentuan yang berlaku. Baik dalam konteks
          legal hukum di wilayah Negara Kesatuan Republik Indonesia, dan
          norma-norma masyarakat Indonesia. Anda dengan ini pula menyetujui
          Disclaimer kami berikut segala ketentuan nya.
        </DialogBody>
        <DialogFooter>
          <Button variant="gradient" color="green" onClick={handleOpen}>
            <span>SETUJU</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}

function App() {
  return (
    <main
      className="my-0 mx-auto min-h-full max-w-screen-sm"
      style={{ maxWidth: "480px" }}
    >
      <DialogDefault />
      <div className="my-0 mx-auto min-h-screen max-h-screen max-w-480 overflow-hidden bg-white pb-[66px]">
        <header className="pt-14">
          <div className="px-20">
            <img className="mx-auto sm:8" src={logo} alt="logo" />
          </div>
          <h1 className="text-center text-7xl text-gray-900 mt-8">
            Berbagi & <br></br>Peduli
          </h1>
        </header>
        <div className="mt-14 px-20">
          <button className="login-button text-white font-bold py-2 px-4 rounded w-full h-14 text-xl">
            Login
          </button>
          <button className="signup-button text-white font-bold py-2 px-4 rounded w-full mt-4 h-14 text-xl">
            Sign Up
          </button>
        </div>
      </div>
    </main>
  );
}

export default App;
